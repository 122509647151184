<template>
  <LayoutsNavbar />
 <main>
 <section class="section-error">
  <div class="container">
    <div class="error-page">
      <img src="/assets/images/Humaaans1.png" alt="human icon" loading="lazy" style="width: 300px; height: fit-content;">
    <h1 class="error-title">404 Page not found</h1>
    <p class="error-message">
     This page was not found. You may have mistyed the address or the page may have moved.
    </p>
    <button @click="goback" class="button primary"><i class="fa-solid fa-arrow-left"></i> Go back to home</button>
  </div>
  </div>
 </section>
 </main>
  <LayoutsFooter />
</template>

<script setup lang="ts">
import LayoutsNavbar  from '@/components/layouts/Navbar.vue';
import LayoutsFooter  from '@/components/layouts/Footer.vue';
const router = useRouter()
const errorMessage = ref('') // Pass detailed error information from parent component

const goback = () => {
  // router.go(-1)
  router.push('/')
}
</script>

<style scoped lang="scss">
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 100px 0;
  .error-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .error-message {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }

  .error-details {
    font-size: 1rem;
    color: #888;
    margin-bottom: 20px;
  }
  button{
    display: flex;
    gap: 10px;
  }
}
</style>
