import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgo108JobsLogo, LazySvgo108JobLogoCircle, LazySvgoBenefits1, LazySvgoBenefits2, LazySvgoBenefits3, LazySvgoBenefits4, LazySvgoBenefits5, LazySvgoBenefits6, LazySvgoBenefits7, LazySvgoBenefits8, LazySvgoBenefits9, LazySvgoDownloadAppPagePhoneDesktop, LazySvgoDownloadAppPagePhoneMobile, LazySvgoFacebook, LazySvgoGoogle, LazySvgoLogos108jobsLogoWhite } from '#components'
const lazyGlobalComponents = [
  ["Svgo108JobsLogo", LazySvgo108JobsLogo],
["Svgo108JobLogoCircle", LazySvgo108JobLogoCircle],
["SvgoBenefits1", LazySvgoBenefits1],
["SvgoBenefits2", LazySvgoBenefits2],
["SvgoBenefits3", LazySvgoBenefits3],
["SvgoBenefits4", LazySvgoBenefits4],
["SvgoBenefits5", LazySvgoBenefits5],
["SvgoBenefits6", LazySvgoBenefits6],
["SvgoBenefits7", LazySvgoBenefits7],
["SvgoBenefits8", LazySvgoBenefits8],
["SvgoBenefits9", LazySvgoBenefits9],
["SvgoDownloadAppPagePhoneDesktop", LazySvgoDownloadAppPagePhoneDesktop],
["SvgoDownloadAppPagePhoneMobile", LazySvgoDownloadAppPagePhoneMobile],
["SvgoFacebook", LazySvgoFacebook],
["SvgoGoogle", LazySvgoGoogle],
["SvgoLogos108jobsLogoWhite", LazySvgoLogos108jobsLogoWhite],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
