<template>
  <div>
    <AppAlert />
    <LayoutsNavbar />
    <PopUpBanner v-show="bannerState.isPopUpModal && route.path === '/'" />
    <main>
      <slot />
    </main>
    <LayoutsFooter />
  </div>
</template>

<script lang="ts" setup>
import LayoutsNavbar from '../components/layouts/Navbar.vue'
import LayoutsFooter from '../components/layouts/Footer.vue'
import AppAlert from '../components/app-alert.vue'
import PopUpBanner from '~/components/modal/PopUpBanner.vue'
import { useBannerStore } from '~/store/banner'
const bannerState = useBannerStore()
const route = useRoute()
</script>
<style lang="scss"></style>
