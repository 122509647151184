<template>
  <div class="container">
    <div class="downloadLink-container">
      <div class="is-left">
        <!-- content and CTA -->
        <div class="description">
          <h2>{{ $t('download app') }}</h2>
          <p>
            {{ $t('download description') }}
          </p>
        </div>
        <div class="link">
          <!-- add bitly download link  -->
          <a href="https://apps.apple.com/th/app/108-jobs/id1169598302?" target="_blank">
            <img src="/assets/images/app-store-badge.png" alt="App Store" loading="lazy" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.onehundredeightjobs.app&pcampaignid=web_share"
            target="_blank">
            <img src="/assets/images/google-play-badge.png" loading="lazy" alt="play Store" />
          </a>
        </div>
      </div>
      <div class="is-right">
        <!-- image  -->
        <img src="/assets/images/home/model_small_bg.png" loading="lazy" alt="girl with mobile" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// swiperBreakpoint
const swiperBreakpoint = {
  320: { slidesPerView: 1, spaceBetween: 8 },
  640: { slidesPerView: 2, spaceBetween: 16 },
  760: { slidesPerView: 3, spaceBetween: 16 }
}

const isLoading = ref<any>(false)
</script>

<style lang="scss" scoped>
.section-header-start {
  width: 100%;

  h3 {
    text-align: center;
  }
}

.downloadLink-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 769px) {
    flex-direction: column;
    margin-top: 2rem;
    text-align: center;
  }

  /* Mobile devices */
  @media (max-width: 769px) {
    .is-left {
      width: 100%;
    }
  }

  .is-left {
    width: 50%;

    .description {
      h2 {
        font-size: var(--xlg-font);
        font-weight: 700;
        margin-bottom: 0.25rem;
      }

      p {
        font-size: var(--lg-font);
        line-height: 1.5;
        margin: 0 0.5rem 1rem 0;
      }
    }

    img {
      display: block;
      max-height: 45px;
    }

    @media (max-width: 769px) {
      .link {
        justify-content: center;
      }
    }

    .link {
      display: flex;
      gap: 10px;

      p {
        user-select: none;
      }
    }

    span {
      color: var(--primary-color);
    }
  }

  @media (max-width: 769px) {
    .is-right {
      width: 100%;
    }
  }

  .is-right {
    width: 50%;
    max-width: 500px;

    img {
      display: block;
    }
  }
}
</style>
