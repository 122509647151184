<template>
  <div class="app-alert-container">
    <div class="start">
      <div class="logo">
        <img
          src="https://lab-108-bucket.s3.ap-southeast-1.amazonaws.com/assistant-logo/108.Jobs-Logo.svg"
        />
      </div>
      <div class="name">
        <h3>108 Jobs</h3>
        <p>Open in 108 Jobs app</p>
      </div>
    </div>
    <div class="end">
      <a href="https://onelink.to/7x6ppq" target="_blank">Open</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Mobile devices */
@media (max-width: 768px) {
  .app-alert-container {
    display: flex;
  }
}
.app-alert-container {
  z-index: 9999;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 3rem;
  background-color: var(--primary-color);
  box-shadow: 0 0 0 1px rgba(#fff, 0.15);
  display: none;

  .start {
    font-size: var(--sm-font);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .logo {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 6px;
      img {
        width: 1.5rem;
        object-fit: cover;
      }
    }
    .name {
      h3 {
        font-weight: 700;
        line-height: 1.5;
        font-size: var(--xsm-font);
      }
      p {
        font-size: var(--xxsm-font);
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  .end {
    font-size: var(--md-font);
    background-color: #fff;
    color: var(--primary-color);
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
}
</style>
