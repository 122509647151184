import { defineStore } from 'pinia'

export const useBannerStore = defineStore('banners', () => {
  // fetch userData
  const isPopUpModal = ref(false)
  const PopUpLength = ref()
  const PopUpModal = (val: boolean) => {
    isPopUpModal.value = val
  }

  //share modal
  const isShareModal = ref(false)
  const ShareModal = (val: boolean) => {
    isShareModal.value = val
  }
  //share Popup
  const isPopModal = ref(false)
  const PopModal = (val: boolean) => {
    isPopModal.value = val
  }

  return {
    isPopUpModal,
    PopUpModal,
    PopUpLength,
    isShareModal,
    ShareModal,
    isPopModal,
    PopModal
  }
})
