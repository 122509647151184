<template>
  <div class="urgent-job">
    <div class="urgent-tag" @click="showUrgent = !showUrgent">
      <p>
        <i class="far fa-megaphone"></i>
        <span>Urgent job</span>
        <i class="far fa-minus" v-if="showUrgent === true"></i>
        <i class="far fa-plus" v-if="showUrgent === false"></i>
      </p>
    </div>
    <!-- end tag  -->

    <!-- Loop Job -->
    <client-only>
      <swiper
        :modules="[SwiperAutoplay]"
        :space-between="16"
        :loop="false"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true
        }"
        :breakpoints="swiperBreakpoint"
      >
        <swiper-slide
          v-for="(i, index) in urgentJobs"
          :key="index"
          :class="{ active: showUrgent }"
          class="urgent-job-card-container"
        >
          <div class="urgent-job-card">
            <div class="logo">
              <img :src="urlImage + i.logo" alt="" />
            </div>
            <div class="job-detail">
              <nuxt-link class="job-title" :to="'/job_detail/' + i._id">
                <h3>{{ i.title }}</h3>
              </nuxt-link>
              <p>{{ i.companyName }}</p>
              <p>{{ i.workingLocations }}</p>
              <p>
                {{ moment(i.openingDate).format('DD/MMM/YY') }} -
                {{ moment(i.closingDate).format('DD/MMM/YY') }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import moment from 'moment'
import SaveJob from '../reuse/saveJob.vue'
const props = defineProps(['urgentJobs'])
const urlImage = useNuxtApp().$urlImage
const emit = defineEmits(['setdata'])
// data variable
const showUrgent = ref<any>(true)

// swiperBreakpoint
const swiperBreakpoint = {
  320: { slidesPerView: 1, spaceBetween: 0 },
  480: { slidesPerView: 1, spaceBetween: 16 },
  640: { slidesPerView: 1, spaceBetween: 16 },
  760: { slidesPerView: 1, spaceBetween: 16 }
}
</script>

<style lang="scss" scoped>
@media (max-width: 769px) {
  .urgent-job {
    max-width: 100% !important;
  }
  .urgent-job-card {
    border-radius: 0 !important;
  }
  .logo {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.urgent-job {
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  max-width: 420px;

  .urgent-tag {
    color: #fff;
    display: flex;
    position: relative;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;

    p {
      border-radius: 6px 0 0 0;
      line-height: 1;
      background-color: var(--danger-color);
      padding: 10px;
      text-transform: uppercase;
      font-size: var(--xsm-font);

      span {
        margin: 0 5px 0 10px;
      }
    }
  }

  // job card
  .urgent-job-card-container {
    max-height: 0;
    cursor: pointer;
    box-shadow: -3px -3px 12px 0px rgba(#000, 0.05);
    transition: max-height ease-out 0.3s;

    &.active {
      max-height: 400px;
    }

    .urgent-job-card {
      align-items: center;
      color: var(--text-color);
      display: flex;
      padding: 1rem;
      background-color: var(--info-color);
      border: none;
      gap: 1rem;
      min-height: 100%;
      min-height: 5.5rem;
      border-radius: 10px 0 0 0;

      .logo {
        display: flex;
        align-items: center;
        background-color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 10px;
        min-width: 70px;
        min-height: 70px;
        max-width: 70px;
        max-height: 70px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }

      a {
        color: var(--text-color);
      }

      .job-detail {
        width: 100%;
        overflow: hidden;

        h3 {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 700;
          font-size: var(--md-font);

          &:hover {
            color: var(--primary-color);
          }
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: var(--xsm-font);
          line-height: 1.5;
        }
      }
    }
  }
}
</style>
