<template>
  <section class="homepage-section">
    <div class="container">
      <div class="loading-container topBanner-loading" v-if="isLoading">
        <div class="loading-item"></div>
      </div>
      <div class="swiper-container no-padding" v-else>
        <client-only>
          <Swiper class="swiper" ref="mySwipy" @click="clickBanner" @slide-change="viewBanner" :modules="[
            SwiperAutoplay,
            SwiperEffectCreative,
            SwiperNavigation,
            SwiperPagination
          ]" :slides-per-view="1" :loop="false" navigation :pagination="{
              clickable: true,
              el: '.swiper-pagination'
            }" :autoplay="{
              delay: 10000,
              disableOnInteraction: true
            }">
            <SwiperSlide tag="div" class="top-banner" v-for="(b, index) in banNer" :key="index">
              <a :href="b.url" target="_blank" class="desktop-screen">
                <img :src="b.image.src" alt="cover" style="cursor: pointer" rel="preload" />
              </a>

              <!-- mobile version  -->
              <a :href="b.url" target="_blank" class="mobile-screen">
                <img :src="b.mobileImage || b.image.src" alt="cover" style="cursor: pointer" rel="preload" />
              </a>
            </SwiperSlide>
            <div class="swiper-pagination"></div>
          </Swiper>
        </client-only>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import useFetchCustom from '@/services/global-useFetch'
// data
const banNer = ref<any>([])
const realIndex = ref<any>(0)
const isLoading = ref<boolean>(false)
const viewedIndexes: Set<number> = new Set()
const viewBanner = async (slideData: any) => {
  const index = slideData.realIndex // set index from slide
  if (!viewedIndexes.has(index)) {
    const idx = banNer.value[index]._id
    try {
      const body = {
        bannerId: idx,
        type: 'View'
      }
      await useFetchCustom('/banner-view-web').post(body)
    } catch (error) { }
    viewedIndexes.add(index)
  }
}

// fetch setup
const fetchTopBanner = async () => {
  try {
    isLoading.value = true
    const body = {
      bannerType: 'Top Banner',
      deviceType: 'web'
    }
    const { data, error, statusCode } = await useFetchCustom(
      '/banner-slide-web'
    )
      .post(body)
      .json()
    if (statusCode.value === 200) {
      const res: any = data.value
      banNer.value = res.banner
      realIndex.value = 0
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}
// click function
const clickBanner = async (slideData: any) => {
  try {
    const curId = banNer.value[slideData.realIndex]._id
    const addClick = {
      bannerId: curId
    }
    await useFetchCustom('/banner-click-web').post(addClick)
  } catch (e) {
    throw e
  }
}

// fetching
fetchTopBanner()
</script>

<style lang="scss">
.top-banner {
  a {
    display: none;

    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
      border-radius: var(--round-md);
    }
  }

  .desktop-screen {
    @media (min-width: 769px) {
      display: block;
    }

    img {
      aspect-ratio: 16/6;
    }
  }

  .mobile-screen {
    @media (max-width: 769px) {
      display: block;
    }

    img {
      aspect-ratio: 16/9;
    }
  }
}

.topBanner-loading {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/6;

  .loading-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
