<template>
  <div class="company-card">
    <div class="cover">
      <img
        :src="getUrlImage + data.cardCover"
        alt="Cover"
        v-if="data.cardCover"
      />
      <div v-else class="blank">
        <!-- <i class="fa-light fa-image"></i> -->
        <p>ພື້ນທີ່ໂຄສະນາບໍລິສັດ --> <b>ຟຣີ</b></p>
        <p>ສົນໃຈຕິດຕໍ່: <a>info@108.jobs</a></p>
      </div>
      <!-- <img
        v-else
        src="../../assets/images/allCompanies/placeholder.jpg"
        alt="placeholder"
      /> -->
    </div>
    <div class="card-content">
      <div class="card-content-header">
        <div class="logo">
          <img :src="getUrlImage + data.logo" alt="logo" />
        </div>
      </div>
      <div class="card-content-body">
        <a :href="'/company-profile/' + data._id">
          <h1>{{ data.companyName }}</h1>
        </a>
        <ul>
          <li><i class="fa-regular fa-industry"></i>{{ data.industry }}</li>
          <li><i class="fa-regular fa-location-dot"></i>{{ data.address }}</li>
        </ul>
      </div>
    </div>
    <div class="card-content-footer has-button" v-if="data.jobsCount">
      <a :href="`/company-profile/${data._id}`">
        <button class="button light-primary is-small">
          {{ $t('view') }}
          <span>{{ data.jobsCount }}</span>
          {{ $t('jobs') }}
        </button>
      </a>
    </div>
    <div class="card-content-footer" v-else>
      <p>{{ data.followerTotal }} {{ $t('follower') }}</p>
      <FollowCompany
        :employerId="data._id"
        :isFollowed="data.follow"
        :style="'is-small light-grey light-primary'"
        @setdata="(value: any) => emit('updateData', value)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FollowCompany from '~/components/reuse/FollowCompany.vue'
// Accessing the `urlImage` getter
const getUrlImage = useNuxtApp().$urlImage
const props = defineProps(['data'])
const emit = defineEmits(['updateData'])
const router = useRouter()
</script>

<style scoped lang="scss">
.company-card {
  background-color: #fff;
  border-radius: var(--round-md);
  position: relative;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  .cover {
    overflow: hidden;
    img {
      aspect-ratio: 5/2;
      border: none;
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .blank {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 1.3rem;
      gap: 0.5rem;
      aspect-ratio: 5/2;
      width: 100%;
      height: 100%;
      background-color: var(--primary-100);
      transition: all ease-in-out 0.2s;
      cursor: pointer;
      &:hover {
        background-color: var(--primary-900);
        p {
          color: #fff;
          a {
            color: var(--info-color);
            &::before {
              max-width: 100%;
            }
          }
        }
      }

      p {
        color: var(--black-600);
        font-size: var(--xsm-font);
        line-height: 1.2;
        b {
          font-weight: 700;
        }
        a {
          cursor: pointer;
          font-weight: 700;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            display: block;
            height: 2px;
            background-color: var(--info-color);
            z-index: 1;
            width: 100%;
            max-width: 0;
            transition: max-width 0.35s ease-in-out;
          }
        }
      }
    }
  }
  .card-content {
    .card-content-header {
      position: relative;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0 1rem;
      .logo {
        border: 1px solid var(--border-color);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: relative;
        min-width: 70px;
        min-height: 70px;
        max-width: 70px;
        max-height: 70px;
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 70px;
        img {
          width: 100%;
          min-width: 40px;
          min-height: 40px;
          max-width: 40px;
          max-height: 40px;
          object-fit: contain;
        }
      }
    }
    .card-content-body {
      padding: 1rem 1rem 0.5rem;
      h1 {
        text-align: center;
        cursor: pointer;
        margin-bottom: 0.2rem;
        font-weight: 700;
        font-size: var(--md-font);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        transition: all 0.1s ease-in-out;
        &:hover {
          color: var(--primary-color);
        }
      }

      ul {
        text-align: center;
        margin-top: 0.5rem;
        li {
          color: var(--grey-color);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: var(--sm-font);
          line-height: 1.5;
          gap: 0.25rem;
          i {
            font-size: var(--xsm-font);
            font-weight: 700;
            color: var(--black-500);
            margin-right: 0.5rem;
            width: 15px;
            height: 15px;
          }
        }
      } //
    }
  }
  .card-content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem 1rem;
    p {
      font-size: var(--xsm-font);
    }
    &.has-button {
      display: flex;
      justify-content: center;
      button {
        font-size: var(--sm-font);
        font-weight: 500;
        span {
          font-weight: 700;
          margin: 0 0.25rem;
        }
      }
    }
  }
}
</style>
