import moment from 'moment'
import { useI18n } from 'vue-i18n'
export const formateDate = (date: any) => {
  return moment(date).format('DD/MMM/YYYY')
}
export const duration = (i: any) => {
  // i is the field openingDate
  const { t } = useI18n()
  const date = moment(i)
  const now = moment()
  const diff = moment.duration(now.diff(date))

  let postDuration
  if (diff.asMinutes() < 59) {
    const isMinutes = parseInt(diff.asMinutes() + `${t('minute')}`)
    postDuration =
      isMinutes > 1
        ? `${isMinutes} ${t('minutes ago')}`
        : `${isMinutes} ${t('minute ago')}` // Use template literals for better readability
  } else if (diff.asHours() < 24) {
    const isHours = parseInt(diff.asHours() + ` ${t('hour')}`)
    postDuration =
      isHours > 1
        ? `${isHours} ${t('hours ago')}`
        : `${isHours} ${t('hour ago')}`
  } else if (diff.asDays() < 7) {
    const isDays = parseInt(diff.asDays() + ` ${t('day')}`)
    postDuration =
      isDays > 1 ? `${isDays} ${t('days ago')}` : `${isDays} ${t('day ago')}`
  } else if (diff.asWeeks() < 4) {
    // Changed condition to calculate weeks directly
    const isWeeks = parseInt(diff.asWeeks() + ` ${t('week')}`) // Changed display to weeks
    postDuration =
      isWeeks > 1
        ? `${isWeeks} ${t('weeks ago')}`
        : `${isWeeks} ${t('week ago')}`
  } else {
    // Calculate days since opening
    const daysSinceOpening = diff.asDays()

    // Calculate months (consider whole months only)
    const months = Math.floor(daysSinceOpening / 30)

    // Calculate remaining days
    const remainingDays = Math.floor(daysSinceOpening % 30)

    if (months > 0) {
      const monthString =
        months === 1 ? `${months} ${t('month')}` : `${months} ${t('months')}`
      postDuration =
        remainingDays > 0
          ? `${monthString} ${remainingDays} ${t('days ago')}`
          : monthString + ` ${t('ago')}`
    } else {
      // Handle case where it's less than a month but more than a week
      if (remainingDays > 1) {
        const isDays = parseInt(remainingDays + ` ${t('days')}`)
        postDuration =
          isDays > 1
            ? `${isDays} ${t('days ago')}`
            : `${isDays} ${t('day ago')}`
      } else {
        // Use existing logic for weeks
        const isWeeks = parseInt(diff.asWeeks() + ` ${t('week')}`)
        postDuration =
          isWeeks > 1
            ? `${isWeeks} ${t('weeks ago')}`
            : `${isWeeks} ${t('weeks ago')}`
      }
    }
  }

  return postDuration
}
