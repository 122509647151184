import {
  seekerInformation,
  seekerUrl,
  employerUrl,
  Base_Url,
  Base_Urls,
  httpsUrl
} from '~/services/global-variable'
export default defineNuxtPlugin((nuxtApp) => {
  const https = httpsUrl
  const Base_URLS = Base_Urls
  const Base_URL = Base_Url
  const seekerInfo = seekerInformation
  const seekerURL = seekerUrl
  const employer = employerUrl
  return {
    provide: {
      https,
      seekerURL,
      employer,
      seekerInfo,
      Base_URLS,
      Base_URL
    }
  }
})
