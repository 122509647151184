import { default as about_45usxh4Yn5B3LnMeta } from "/vercel/path0/pages/about-us.vue?macro=true";
import { default as _91name_93BCLd1sJ7RPMeta } from "/vercel/path0/pages/article/[name].vue?macro=true";
import { default as indexOhbqgW70GLMeta } from "/vercel/path0/pages/article/index.vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as registerUZDCsj62s6Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as Verify_45otpFuWuHw9TTMMeta } from "/vercel/path0/pages/auth/Verify-otp.vue?macro=true";
import { default as _91id_93wfsTZrz7HjMeta } from "/vercel/path0/pages/company-profile/[id].vue?macro=true";
import { default as Benefit385RlGpmi1Meta } from "/vercel/path0/pages/company-profile/section/Benefit.vue?macro=true";
import { default as ContactbhkAhu7RhHMeta } from "/vercel/path0/pages/company-profile/section/Contact.vue?macro=true";
import { default as Gallery3CYBWejqxTMeta } from "/vercel/path0/pages/company-profile/section/Gallery.vue?macro=true";
import { default as JobOpenUBn9JTk9vkMeta } from "/vercel/path0/pages/company-profile/section/JobOpen.vue?macro=true";
import { default as ServiceZNszPFyJPVMeta } from "/vercel/path0/pages/company-profile/section/Service.vue?macro=true";
import { default as VideoQKvOm71RE9Meta } from "/vercel/path0/pages/company-profile/section/Video.vue?macro=true";
import { default as indexXWzlomiZOFMeta } from "/vercel/path0/pages/company-search/index.vue?macro=true";
import { default as SingleSelectgIWYOU61xUMeta } from "/vercel/path0/pages/company-search/SingleSelect.vue?macro=true";
import { default as TabBoxJFcvIAnAL2Meta } from "/vercel/path0/pages/company-search/TabBox.vue?macro=true";
import { default as download_45app6ER5HArmfcMeta } from "/vercel/path0/pages/download-app.vue?macro=true";
import { default as _91id_939WSWf1edn3Meta } from "/vercel/path0/pages/featured-company/[id].vue?macro=true";
import { default as Company_45NavbarF2cVBVIePHMeta } from "/vercel/path0/pages/featured-company/section/Company-Navbar.vue?macro=true";
import { default as JobsViFDAYiXObMeta } from "/vercel/path0/pages/featured-company/section/Jobs.vue?macro=true";
import { default as PeopleNHgbHwRntkMeta } from "/vercel/path0/pages/featured-company/section/People.vue?macro=true";
import { default as index09rJVUvML3Meta } from "/vercel/path0/pages/forgot-password/index.vue?macro=true";
import { default as CompleteResetzSPM0Ngt7NMeta } from "/vercel/path0/pages/forgot-password/section/CompleteReset.vue?macro=true";
import { default as ForgotLinkok6agejI7LMeta } from "/vercel/path0/pages/forgot-password/section/ForgotLink.vue?macro=true";
import { default as InputFieldTbRgC36rdUMeta } from "/vercel/path0/pages/forgot-password/section/InputField.vue?macro=true";
import { default as OTPela2XoUzUMMeta } from "/vercel/path0/pages/forgot-password/section/OTP.vue?macro=true";
import { default as ResetPasswordHk3aKhcciSMeta } from "/vercel/path0/pages/forgot-password/section/ResetPassword.vue?macro=true";
import { default as _91id_93lSkpESU2OlMeta } from "/vercel/path0/pages/get-cv/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93ycmsErlVjbMeta } from "/vercel/path0/pages/job_detail/[id].vue?macro=true";
import { default as RelatedJobEOfV8cHNoyMeta } from "/vercel/path0/pages/job_detail/RelatedJob.vue?macro=true";
import { default as indexucVhVcUI89Meta } from "/vercel/path0/pages/job-search/index.vue?macro=true";
import { default as RecommendedjobNoImagebMT3GcP2RuMeta } from "/vercel/path0/pages/job-search/RecommendedjobNoImage.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as indexZqjRDHar7KMeta } from "/vercel/path0/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/vercel/path0/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "article-name",
    path: "/article/:name()",
    component: () => import("/vercel/path0/pages/article/[name].vue").then(m => m.default || m)
  },
  {
    name: "article",
    path: "/article",
    component: () => import("/vercel/path0/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/vercel/path0/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-Verify-otp",
    path: "/auth/Verify-otp",
    component: () => import("/vercel/path0/pages/auth/Verify-otp.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-id",
    path: "/company-profile/:id()",
    component: () => import("/vercel/path0/pages/company-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-Benefit",
    path: "/company-profile/section/Benefit",
    component: () => import("/vercel/path0/pages/company-profile/section/Benefit.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-Contact",
    path: "/company-profile/section/Contact",
    component: () => import("/vercel/path0/pages/company-profile/section/Contact.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-Gallery",
    path: "/company-profile/section/Gallery",
    component: () => import("/vercel/path0/pages/company-profile/section/Gallery.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-JobOpen",
    path: "/company-profile/section/JobOpen",
    component: () => import("/vercel/path0/pages/company-profile/section/JobOpen.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-Service",
    path: "/company-profile/section/Service",
    component: () => import("/vercel/path0/pages/company-profile/section/Service.vue").then(m => m.default || m)
  },
  {
    name: "company-profile-section-Video",
    path: "/company-profile/section/Video",
    component: () => import("/vercel/path0/pages/company-profile/section/Video.vue").then(m => m.default || m)
  },
  {
    name: "company-search",
    path: "/company-search",
    component: () => import("/vercel/path0/pages/company-search/index.vue").then(m => m.default || m)
  },
  {
    name: "company-search-SingleSelect",
    path: "/company-search/SingleSelect",
    component: () => import("/vercel/path0/pages/company-search/SingleSelect.vue").then(m => m.default || m)
  },
  {
    name: "company-search-TabBox",
    path: "/company-search/TabBox",
    component: () => import("/vercel/path0/pages/company-search/TabBox.vue").then(m => m.default || m)
  },
  {
    name: "download-app",
    path: "/download-app",
    meta: download_45app6ER5HArmfcMeta || {},
    component: () => import("/vercel/path0/pages/download-app.vue").then(m => m.default || m)
  },
  {
    name: "featured-company-id",
    path: "/featured-company/:id()",
    meta: _91id_939WSWf1edn3Meta || {},
    component: () => import("/vercel/path0/pages/featured-company/[id].vue").then(m => m.default || m)
  },
  {
    name: "featured-company-section-Company-Navbar",
    path: "/featured-company/section/Company-Navbar",
    component: () => import("/vercel/path0/pages/featured-company/section/Company-Navbar.vue").then(m => m.default || m)
  },
  {
    name: "featured-company-section-Jobs",
    path: "/featured-company/section/Jobs",
    component: () => import("/vercel/path0/pages/featured-company/section/Jobs.vue").then(m => m.default || m)
  },
  {
    name: "featured-company-section-People",
    path: "/featured-company/section/People",
    component: () => import("/vercel/path0/pages/featured-company/section/People.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/vercel/path0/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-section-CompleteReset",
    path: "/forgot-password/section/CompleteReset",
    component: () => import("/vercel/path0/pages/forgot-password/section/CompleteReset.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-section-ForgotLink",
    path: "/forgot-password/section/ForgotLink",
    component: () => import("/vercel/path0/pages/forgot-password/section/ForgotLink.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-section-InputField",
    path: "/forgot-password/section/InputField",
    component: () => import("/vercel/path0/pages/forgot-password/section/InputField.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-section-OTP",
    path: "/forgot-password/section/OTP",
    component: () => import("/vercel/path0/pages/forgot-password/section/OTP.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-section-ResetPassword",
    path: "/forgot-password/section/ResetPassword",
    component: () => import("/vercel/path0/pages/forgot-password/section/ResetPassword.vue").then(m => m.default || m)
  },
  {
    name: "get-cv-id",
    path: "/get-cv/:id()",
    component: () => import("/vercel/path0/pages/get-cv/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "job_detail-id",
    path: "/job_detail/:id()",
    component: () => import("/vercel/path0/pages/job_detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "job_detail-RelatedJob",
    path: "/job_detail/RelatedJob",
    component: () => import("/vercel/path0/pages/job_detail/RelatedJob.vue").then(m => m.default || m)
  },
  {
    name: "job-search",
    path: "/job-search",
    component: () => import("/vercel/path0/pages/job-search/index.vue").then(m => m.default || m)
  },
  {
    name: "job-search-RecommendedjobNoImage",
    path: "/job-search/RecommendedjobNoImage",
    component: () => import("/vercel/path0/pages/job-search/RecommendedjobNoImage.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  }
]