<template>
  <div class="container">
    <div class="section-header">
      <div class="section-header-start">
        <h2 class="title">{{ $t('find dream job') }}</h2>
        <p>
          {{ $t('explore more') }} <span v-if="allJob">{{ allJob }}</span>
          {{ $t('jobs') }}
        </p>
      </div>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading-item"></div>
      <div class="loading-item"></div>
      <div class="loading-item"></div>
    </div>
    <div
      v-else
      class="grids is-3-desktop is-2-tablet is-1-mobile gap-20-desktop gap-20-tablet gap-10-mobile"
    >
      <div v-for="(i, index) in featuredJobs" :key="index">
        <JobCard :jobs="i" @setData="setData" @fetchData="fetchJob('hide')" />
      </div>
      <div
        v-show="featuredJobs.length >= 5"
        class="blank-job-card"
        @click="$router.push({ path: 'job-search' })"
      >
        <h3>+{{ allJob }}</h3>
        <p>
          <b> {{ $t('explore more') }}</b>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import JobCard from '~/components/reuse/JobCard-2.vue'
import { useAuthStore } from '~/store/auth'
import useFetchCustom from '@/services/global-useFetch'
const store = useAuthStore()
const featuredJobs = ref<any[]>([])
const allJob = ref<any>([])
const isLoading = ref<any>(false)

const setData = (value: any) => {
  const index = featuredJobs.value.findIndex((i: any) => i._id === value._id)
  featuredJobs.value[index].isSaved = !value.isSave
  // alert(index)
}
const fetchJob = async (type: string) => {
  try {
    if (!type) {
      isLoading.value = true
    }
    const body = {
      jobType: '',
      verifyToken: store.token
    }
    const { data, error } = await useFetchCustom('/job-feature-web')
      .post(body)
      .json()
    if (data.value) {
      const res: any = data.value
      featuredJobs.value = res.jobs.slice(0, 5)
      allJob.value = res.onlineJobTotal
    }
    if (!type) {
      isLoading.value = false
    }
  } catch (e) {
    throw e
  }
}

// start FC
fetchJob('')
</script>

<style scoped lang="scss">
.section-header-start {
  p {
    text-align: center;
    font-size: var(--md-font);
  }
}

.blank-job-card {
  cursor: pointer;
  transition: all ease-in-out 0.15s;
  background-color: var(--light-primary-color);
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--round-md);
  min-height: 10rem;
  box-shadow: inset 0 0 0 2px var(--primary-color);
  &:hover {
    background-color: var(--primary-color);
    h3,
    p,
    b {
      color: #fff !important;
    }
  }
  h3 {
    // font-size: 3.5rem;
    font-size: clamp(2.6rem, 5vw, 3rem);
    font-weight: 800;
    color: var(--primary-color);
    line-height: 1;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    color: var(--grey-text-color);
    b {
      color: var(--primary-color);
      font-weight: 600;
    }
  }
}

.loading-container {
  background-color: unset;
  width: 100%;
  height: 300px;
  .loading-item {
    width: 100%;
  }
}
</style>
