import { defineStore } from 'pinia'
import { useAuthStore } from '~/store/auth'
import useFetchCustom from '~/services/global-useFetch'
import { seekerInformation } from '~/services/global-variable'

// Define the User interface
interface User {
  _id: string | null
  fullName: string | null
  profile: string | null
  memberLevel: string | null
  cv: string | null
}

export const useUserStore = defineStore('user', () => {
  const seekerProfile: string = seekerInformation // Assuming this is a URL or API endpoint string
  const Auth = useAuthStore()

  // State store
  const userLevel = ref<string | null>(null)
  const userData = ref<User | null>(null)

  // Fetch userData
  const fetchUserInfo = async () => {
    if(Auth.isAuth === false) return
      try {
        const { data, error, statusCode } = await useFetchCustom(seekerProfile)
          .post()
          .json()
        if (statusCode.value === 200 || data.value) {
          const res: any = data.value
          // Map the API response to the User interface
          const mappedUser: User = {
            _id: res.seekerProfile._id,
            fullName: res.seekerProfile.fullName,
            profile: res.seekerProfile.profile,
            memberLevel: res.seekerProfile.memberLevel,
            cv: res.seekerProfile.cv
          }

          userData.value = mappedUser
          userLevel.value = mappedUser.memberLevel
        }
      } catch (e) {
        console.error('Error fetching user info:', e)
      }
  }

  return {
    userData,
    userLevel,
    fetchUserInfo
  }
})
