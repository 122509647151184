<template>
  <topSliderVue />
  <section class="homepage-section">
    <CardList
      :data="jobByProvinces"
      :title="'job by province'"
      :loading="isLoading"
      :routeQuery="'locationRoute'"
    />
  </section>
  <section class="homepage-section">
    <CardList
      :data="jobByIndustry"
      :title="'job by industry'"
      :loading="isLoading"
      :routeQuery="'industryRoute'"
    />
  </section>

  <section class="homepage-section has-bg-gadient">
    <featuredJobVue />
  </section>

  <SpotlightBanner />

  <section class="homepage-section">
    <FeaturedCompany />
  </section>
  <section class="homepage-section">
    <HiringNow />
  </section>
  <section class="homepage-section has-bg-light">
    <BottomBanner />
  </section>
  <div>
    <urgentJobVue
      v-if="urgentJobs"
      :urgentJobs="urgentJobs"
      @setdata="updateData"
    />
  </div>
</template>
<script lang="ts" setup>
import topSliderVue from '~/components/homepage/TopSlider.vue'
import FeaturedCompany from '~/components/homepage/FeaturedCompany.vue'
import featuredJobVue from '~/components/homepage/FeaturedJob.vue'
import urgentJobVue from '~/components/homepage/UrgentJob.vue'
import BottomBanner from '~/components/homepage/BottomBanner.vue'
import HiringNow from '~/components/homepage/HiringNow.vue'
import useFetchCustom from '@/services/global-useFetch'
import { useAuthStore } from '~/store/auth'
import CardList from '~/components/reuse/CardList-Swiper.vue'
import SpotlightBanner from '~/components/homepage/SpotlightBanner.vue'
const store = useAuthStore()
const route = useRoute()
const host: any = useNuxtApp().$Base_URL

import { useI18n } from 'vue-i18n'
const { locale }: any = useI18n()
// set isLang
const isLang = computed(() => {
  return locale.value ? locale.value.toUpperCase() : 'EN'
})
// set varia
const urgentJobs = ref<any>([])
const jobByProvinces = ref<any>([])
const jobByIndustry = ref<any>([])
const isLoading = ref<boolean>(false)
const config = useRuntimeConfig()

// Urgent job
const updateData = (value: any) => {
  const index = urgentJobs.value.findIndex((i: any) => i._id === value._id)
  urgentJobs.value[index].isSaved = !value.isSave
  // alert(index)
}
const fetchUrgentJobs = async () => {
  try {
    const body = {
      jobType: 'Urgent',
      verifyToken: store.token
    }
    const { data, error } = await useFetchCustom('/job-feature-web')
      .post(body)
      .json()
    if (data.value) {
      const res: any = data.value
      urgentJobs.value = res.jobs
    }
  } catch (error) {
    throw error
  }
}

//fetch job by province
const fetchProvinceIndustry = async () => {
  isLoading.value = true
  try {
    const { data: industry, error: error1 } = await useFetchCustom(
      `/job-group-by-industry-web?lang=${isLang.value}&&type=Industry`
    )
      .get()
      .json()
    const { data: province, error: error2 } = await useFetchCustom(
      `/job-group-by-industry-web?lang=${isLang.value}&&type=Province`
    )
      .get()
      .json()
    if (province.value || industry.value) {
      const resProvince: any = province.value
      const resIndustry: any = industry.value
      if (resProvince) {
        jobByProvinces.value = resProvince.info
      }
      if (resIndustry) {
        jobByIndustry.value = resIndustry.info
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}
// set meta
useHead({
  meta: [
    {
      name: 'url',
      content: host
    }
  ]
})
//watch
watch(
  () => locale.value,
  (value) => {
    fetchProvinceIndustry()
  },
  { immediate: true, deep: true }
)

// start FC

fetchProvinceIndustry()
fetchUrgentJobs()
</script>

<style lang="scss">
.has-task {
  &::before {
    content: '\f762';
    font-family: 'Font Awesome 6 Pro';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    right: -5px;
    z-index: 3;
    font-size: 0.6rem;
    width: 16px;
    height: 16px;
    border-radius: 14px;
    background-color: var(--alert-color);
    color: #fff;
    font-weight: 500;
  }
}

.homepage-section {
  padding: 1.5rem 0;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    @include mobile {
      flex-direction: column;
      margin-bottom: 1rem;
    }
    .section-header-start {
      width: 100%;
      .title {
        font-size: var(--xlg-font);
        font-weight: 700;
        text-transform: initial;
        line-height: 1.3;
        text-align: center;
      }
      p {
        margin-top: 0.5rem;
        span {
          color: var(--primary-color);
          font-weight: 700;
          margin: 0 5px;
          user-select: none;
        }
      }
    }
    .section-header-end {
      a {
        display: flex;
        align-items: center;
        font-size: 1rem;
        background-color: transparent;
        padding-bottom: 5px;
        color: var(--grey-color);
        span {
          color: var(--primary-color);
          font-weight: 700;
          margin: 0 5px;
        }
        i {
          margin-left: 5px;
        }
        &:hover {
          color: var(--primary-color);
          i {
            color: var(--primary-color);
          }
        }
      }
    }

    // a highlight
  }
  &.has-bg-gadient {
    // padding: 0 !important;
    background: #fff;
    background: linear-gradient(0deg, #fff, var(--light-primary-color));
  }
  &.has-bg-light {
    background-color: var(--light-primary-color);
    padding-bottom: 0;
  }
}
</style>
