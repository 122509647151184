<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-content-header">
        <h1>{{ $t('login') }}</h1>
        <div @click="closeModal" class="close">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <div class="modal-content-body">
        <Form @submit="isLogin">
          <!-- phone field  -->
          <div class="field phone" v-if="activeTab === 'phone'">
            <label class="is-grey">
              {{ $t('phone') }}
              <span @click="tabToggle('email')"
                >{{ $t('login with') }}{{ $t('email') }}</span
              >
            </label>

            <div class="control">
              <span>+856 20</span>
              <Field
                class="input mbNum is-success"
                type="text"
                name="phone"
                :placeholder="$t('enter your') + $t('phone')"
                v-model="phoneNumber"
                :rules="validatePhone"
                autocomplete="username"
              />
            </div>
            <ErrorMessage name="phone" class="error" />

            <!-- <p class="help is-danger">text alert</p> -->
          </div>
          <!-- phone  -->

          <!-- email field  -->
          <div class="field" v-if="activeTab === 'email'">
            <label class="is-grey"
              >{{ $t('email') }}
              <span @click="tabToggle('phone')"
                >{{ $t('login with') }}{{ $t('phone') }}</span
              ></label
            >
            <div class="control has-icons">
              <Field
                class="input"
                type="email"
                name="email"
                :placeholder="$t('enter your') + $t('email')"
                v-model="email"
                :rules="validateEmail"
                :class="{ isError: msgError }"
                autocomplete="username"
              />
              <span class="icon icon-left">
                <i class="fa-regular fa-envelope"></i>
              </span>
            </div>
            <ErrorMessage name="email" class="error" />
          </div>
          <!-- email field  -->

          <!-- password field  -->
          <div class="field">
            <label class="is-grey">{{ $t('password') }}</label>
            <div class="control has-icons">
              <Field
                class="input"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('enter') + $t('password')"
                name="password"
                :class="{ isError: msgError }"
                :rules="validatePass"
                autocomplete="current-password"
              />
              <span class="icon icon-left">
                <i class="fa-regular fa-lock"></i>
              </span>
              <span
                @click="togglePasswordVisibility"
                class="icon is-click icon-right"
              >
                <i
                  :class="
                    showPassword
                      ? 'fa-regular fa-eye'
                      : 'fa-regular fa-eye-slash'
                  "
                ></i>
              </span>
            </div>
            <ErrorMessage name="password" class="error" />
            <div class="forgot-password">
              <p @click="forgotPage">{{ $t('forgot pass') }}?</p>
            </div>
          </div>
          <!-- password field  -->
          <p v-if="msgError" class="error">{{ msgError }}</p>
          <p v-if="successMsg" class="success">{{ successMsg }}</p>
          <!-- btn field  -->
          <div class="buttons">
            <button class="button primary">{{ $t('login') }}</button>
          </div>

          <div class="divider-line">
            <span>{{ $t('or') }}{{ $t('login with') }}</span>
          </div>

          <div class="other-options">
            <a class="option-item" @click="thirdAuth('gg')">
              <div class="icons">
                <IconGG class="icon" />
              </div>
              <span>Google</span>
            </a>
            <a class="option-item" @click="thirdAuth('fb')">
              <div class="icons">
                <img
                  src="~/assets/images/Facebook_Logo_Primary.png"
                  alt="fb-logo"
                />
              </div>
              <span>Facebook</span>
            </a>
          </div>
        </Form>
      </div>
      <div class="modal-content-footer">
        <p>
          {{ $t("don't have ac") }}?
          <span @click="RegisterPage">{{ $t('register') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useAuthStore } from '~/store/auth'
const emit = defineEmits(['toggleRegister'])
const store = useAuthStore()
import IconGG from '~/assets/images/google.svg?skipsvgo'
const { LoginModal, LoginForm } = store
const activeTab = ref('phone')
const https = useNuxtApp().$https
const router = useRouter()
const props = defineProps(['isLoginModal'])

const closeModal = () => {
  LoginModal(false)
}
const tabToggle = (tab: string) => {
  activeTab.value = tab
}
const RegisterPage = () => {
  store.isRegister = true
  LoginModal(false)
  router.push('/auth/register')
}
const forgotPage = () => {
  LoginModal(false)
  router.push('/forgot-password')
}
// set variable
const email = ref<any>('')
const phoneNumber = ref<any>('')
const password = ref<any>('')
const msgError = ref<any>('')
const successMsg = ref<any>('')
// password show
const showPassword = ref(false)

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}
//Login Function
const isLogin = async (values: any) => {
  const loginTypeCookie = useCookie('loginType')
  let isEmail = values.phone
  if (!values.phone) isEmail = values.email
  const form = {
    email: isEmail,
    password: values.password
  }
  if (window.innerWidth <= 768 && loginTypeCookie.value !== 'cv') {
    router.push('/download-app')
  } else {
    const msg = await LoginForm(form)
    if (msg) {
      msgError.value = msg
    }
  }
}

// set ValidityState
const validateEmail = (value: any) => {
  if (!value) {
    return 'This field is required'
  }
  // if the field is not a valid email
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  if (!regex.test(value)) {
    return 'This field must be a valid email'
  }

  // All is good
  return true
}
const validatePhone = (value: any) => {
  if (!value) {
    return 'This field is required'
  }

  const isValidLength = value.length >= 8 && value.length <= 8
  const isValidFormat = /^\d+$/.test(value)

  if (!isValidLength || !isValidFormat) {
    return 'Please enter a valid phone number (8 digits)'
  }

  // All is good
  return true
}
const validatePass = (value: any) => {
  if (!value) {
    return 'This field is required'
  }

  const isValidLength = value.length >= 8
  const isValidFormat = /^[A-Za-z0-9._%+-]+$/.test(value)

  if (!isValidLength || !isValidFormat) {
    return 'Please enter a valid password (mimimum 8 characters)'
  }

  // All is good
  return true
}
// third party auth
const thirdAuth = (type: string) => {
  if (type == 'fb') {
    window.open(`${https}/login/facebook`, '_self')
  }
  if (type == 'gg') {
    window.open(`${https}/login/google`, '_self')
  }
}
</script>
<style scoped lang="scss">
.modal {
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal-background {
    background-color: rgba(10, 10, 10, 0.86);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .modal-content {
    z-index: 999;
    max-width: 520px;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    padding: 2rem;
    .modal-content-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: var(--lg-font);
        font-weight: 700;
      }
      .close {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--light-grey-color);
        transition: all ease-out 0.2s;
        i {
          font-size: var(--md-font);
          color: var(--grey-color);
        }
        &:hover {
          background-color: var(--light-alert-color);
          i {
            color: var(--alert-color);
          }
        }
      }
    }
    .modal-content-body {
      padding: 1rem 0;
      .field {
        text-align: left;
        margin-bottom: 1rem;
        label {
          font-weight: 600;
          font-size: var(--sm-font);
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.25rem;
          span {
            font-weight: normal;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        input {
          height: 2.8rem;
          border-radius: 6px;
          font-family: var(--font-family);
        }
        .control {
          position: relative;
          display: flex;
          align-items: center;
          &.has-icons {
            input {
              padding: 0 40px;
            }
            .icon {
              cursor: pointer;
              position: absolute;
              width: 40px;
              display: flex;
              justify-content: center;
              color: var(--grey-color);
            }
            .icon-left {
              left: 0;
            }
            .icon-right {
              right: 0;
              cursor: pointer;
            }
            span {
              display: flex;
              align-items: center;
              height: 2.8rem;
              cursor: pointer;
            }
          }
        }
        &.phone {
          .control {
            display: flex;
            align-items: center;
            span {
              font-weight: 600;
              white-space: pre;
              height: 2.8rem;
              padding: 0 15px;
              display: flex;
              align-items: center;
              background-color: var(--border-color);
              border-radius: 6px 0 0 6px;
              -webkit-user-select: none; /* Safari */
              -ms-user-select: none; /* IE 10 and IE 11 */
              user-select: none; /* Standard syntax */
            }
            input {
              border-radius: 0 6px 6px 0;
            }
          }
        }

        .forgot-password {
          width: 100%;
          text-align: end;
          color: var(--alert-color);
          font-size: var(--xsm-font);
          cursor: pointer;
          font-size: var(--sm-font);
        }
      }
      .buttons {
        width: 100%;
        button {
          width: 100%;
          font-size: var(--md-font);
        }
      }
      .divider-line {
        margin: 1rem auto;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 0.5px;
          background-color: var(--border-color);
          position: absolute;
          left: 0;
          right: 0;
        }
        span {
          font-size: var(--sm-font);
          padding: 0 20px;
          background-color: #fff;
          position: relative;
          z-index: 1;
        }
      }
      .other-options {
        display: flex;
        gap: 0.5rem;
        .option-item {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          height: 2.5rem;
          border: 1px solid var(--border-color);
          border-radius: var(--round-sm);
          font-size: var(--sm-font);
          cursor: pointer;
          .icons {
            img,
            .icon {
              min-width: 20px;
              min-height: 20px;
              max-width: 20px;
              max-height: 20px;
              display: block;
              object-fit: contain;
            }
          }
        }
      }
    }
    .modal-content-footer {
      margin-top: 1rem;
      p {
        font-size: var(--sm-font);
        span {
          cursor: pointer;
          color: var(--primary-color);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// error
.error {
  font-size: var(--sm-font);
  width: 100%;
  color: var(--danger-color);
  margin-bottom: 1rem;
}
</style>
