import { createFetch } from '@vueuse/core'
import { baseApi } from './global-variable'
import { useAuthStore } from '~/store/auth'

const useFetchCustom = createFetch({
  baseUrl: baseApi,
  options: {
    async beforeFetch({ options }: { options: RequestInit }) {
      const store = useAuthStore()
      // Ensure Authorization header is set only if token exists
      if (store.token) {
        options.headers = {
          ...options.headers,
          Authorization: store.token // Assuming the token needs to be prefixed with 'Bearer'
        }
      }
      return { options }
    }
  },
  combination: 'overwrite'
})

export default useFetchCustom
